import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				FAQ - Questions fréquemment posées à CoreFlex Gym
			</title>
			<meta name={"description"} content={"Votre parcours de remise en forme, simplifié - Des réponses pour accélérer vos progrès à CoreFlex Gym\n"} />
			<meta property={"og:title"} content={"FAQ - Questions fréquemment posées à CoreFlex Gym"} />
			<meta property={"og:description"} content={"Votre parcours de remise en forme, simplifié - Des réponses pour accélérer vos progrès à CoreFlex Gym\n"} />
			<meta property={"og:image"} content={"https://lyongourmetgold.com/images/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://lyongourmetgold.com/images/free-fitness-gym-4616540-3819408.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://lyongourmetgold.com/images/free-fitness-gym-4616540-3819408.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://lyongourmetgold.com/images/free-fitness-gym-4616540-3819408.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://lyongourmetgold.com/images/free-fitness-gym-4616540-3819408.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://lyongourmetgold.com/images/free-fitness-gym-4616540-3819408.png"} />
			<meta name={"msapplication-TileImage"} content={"https://lyongourmetgold.com/images/free-fitness-gym-4616540-3819408.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://lyongourmetgold.com/images/9.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Se lancer dans le fitness en toute clarté
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Se lancer dans un parcours de remise en forme chez CoreFlex Gym n'est pas sans poser de questions. Nous avons rassemblé les questions les plus courantes pour vous aider à naviguer sur le chemin de votre meilleur moi avec facilité et confiance.
				</Text>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" background="#ffffff" quarkly-title="FAQ-9">
			<Text margin="0px 0px 70px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--darkL1" sm-margin="0px 0px 50px 0px">
				Questions fréquemment posées
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="1fr"
				grid-gap="50px 50px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
				flex-direction="column"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Quelles sont les heures d'ouverture de la salle de sport?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Nos portes sont ouvertes du début de la matinée jusqu'à la fin de la soirée, ce qui vous laisse amplement le temps d'intégrer une séance d'entraînement dans votre emploi du temps chargé.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Dois-je apporter mon propre équipement?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Bien que les articles de confort personnel comme les gants et les tapis soient les bienvenus, CoreFlex Gym est entièrement équipé de tout ce dont vous avez besoin pour une séance d'entraînement complète.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Y a-t-il une séance d'orientation pour les nouveaux membres?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Oui, nous proposons une visite guidée et une séance d'introduction à tous les nouveaux membres afin de les aider à se familiariser avec nos installations et nos services.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Les cours collectifs de fitness sont-ils inclus dans l'abonnement ?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Plusieurs cours collectifs sont inclus dans votre abonnement, ce qui vous permet de profiter d'une variété de séances d'entraînement dans le cadre de votre routine.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Quels types de forfaits d'entraînement personnel proposez-vous ?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Nous disposons d'une gamme de forfaits d'entraînement personnel adaptés à différents objectifs, préférences et emplois du temps.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Comment puis-je m'inscrire ?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Il est facile de s'inscrire à CoreFlex Gym - il suffit de passer à notre réception ou de nous appeler, et nous vous guiderons tout au long du processus d'inscription.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});